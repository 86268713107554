import styled from 'styled-components';

const SearchControlsStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    max-width: 100%;
    background-color: ${(props) => props.theme.colors.background.container};

    .icon-back {
        all: unset;
        cursor: pointer;
        margin-right: 5px;

        @media (min-width: 1200px) {
            display: none;
        }
    }

    @media (max-width: 1100px) {
        display: none;
    }

    @media (max-width: 815px) {
        height: 82px;
        padding-left: 8px;
        padding-right: 8px;
        justify-content: center;
        display: flex;
    }

    @media (max-width: 450px) {
        justify-content: flex-start;
        padding-left: 6px;
        display: flex;
    }
`;

export { SearchControlsStyled };
