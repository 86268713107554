import { WebstorePriceTiers } from 'framework';

const eventTypes = [
    {
        label: 'Forms.UserBasics.EventTypes.Option1',
        value: 'Wedding',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option2',
        value: 'Prom',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option3',
        value: 'FirstCommunion',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option4',
        value: 'Homecoming',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option5',
        value: 'Graduation',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option6',
        value: 'Group',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option7',
        value: 'Quinceanera',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option8',
        value: 'SpecialOccasion',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option9',
        value: 'MilitaryBall',
    },
    {
        label: 'Forms.UserBasics.EventTypes.Option10',
        value: 'PartyOnly',
    },
];

const weddingUserEventRoles = [
    {
        label: 'Forms.UserBasics.UserEventRoles.Option1',
        value: 'Bride',
    },
    {
        label: 'Forms.UserBasics.UserEventRoles.Option2',
        value: 'Bridesmaid',
    },
    {
        label: 'Forms.UserBasics.UserEventRoles.Option3',
        value: 'MotherOfBrideGroom',
    },
    {
        label: 'Forms.UserBasics.UserEventRoles.Option4',
        value: 'GrandmotherOfBrideGroom',
    },
    {
        label: 'Forms.UserBasics.UserEventRoles.Option5',
        value: 'PartyGoerOther',
    },
    {
        label: 'Forms.UserBasics.UserEventRoles.Option6',
        value: 'Partner',
    },
];

const userEventRoles = [
    {
        label: 'Forms.UserBasics.UserEventRoles.Option5',
        value: 'PartyGoerOther',
    },
];

const eventLocationType = [
    {
        label: 'Forms.EventLocation.EventLocationType.Option1',
        value: 'local',
    },
    {
        label: 'Forms.EventLocation.EventLocationType.Option2',
        value: 'destination',
    },
    {
        label: 'Forms.EventLocation.EventLocationType.Option3',
        value: 'unknown',
    },
];

const budgetTiers = [
    {
        id: 'price-tier-budget-friendly',
        value: WebstorePriceTiers.BUDGETFRIENDLYVALUE,
        title: 'Forms.EventDetails.BudgetTiers.Tier1.Title',
        body: 'Forms.EventDetails.BudgetTiers.Tier1.Subtitle',
        monetarySymbol: 'Forms.EventDetails.BudgetTiers.Tier1.MonetarySymbols',
    },
    {
        id: 'price-tier-standard',
        value: WebstorePriceTiers.STANDARDVALUE,
        title: 'Forms.EventDetails.BudgetTiers.Tier2.Title',
        body: 'Forms.EventDetails.BudgetTiers.Tier2.Subtitle',
        monetarySymbol: 'Forms.EventDetails.BudgetTiers.Tier2.MonetarySymbols',
    },
    {
        id: 'price-tier-premium',
        value: WebstorePriceTiers.PREMIUMVALUE,
        title: 'Forms.EventDetails.BudgetTiers.Tier3.Title',
        body: 'Forms.EventDetails.BudgetTiers.Tier3.Subtitle',
        monetarySymbol: 'Forms.EventDetails.BudgetTiers.Tier3.MonetarySymbols',
    },
    {
        id: 'price-tier-luxury',
        value: WebstorePriceTiers.LUXURYVALUE,
        title: 'Forms.EventDetails.BudgetTiers.Tier4.Title',
        body: 'Forms.EventDetails.BudgetTiers.Tier4.Subtitle',
        monetarySymbol: 'Forms.EventDetails.BudgetTiers.Tier4.MonetarySymbols',
    },
];

const eventDateFormDropdownOptions = {
    options: [
        {
            label: 'Forms.EventDate.Option1',
            value: '1',
        },
        {
            label: 'Forms.EventDate.Option2',
            value: '2',
        },
        {
            label: 'Forms.EventDate.Option3',
            value: '3',
        },
    ],
};

export {
    eventTypes,
    userEventRoles,
    eventLocationType,
    budgetTiers,
    eventDateFormDropdownOptions,
    weddingUserEventRoles,
};
